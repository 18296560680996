import React, { Fragment, useState, useEffect } from "react";
import api from "../../../axios/api";
import moment from "moment";
import { useLogin } from "./../../../context/Provider";

const DetailsDate = () => {
  const { token, setToken, setIsLoggedin } = useLogin();
  const [btnText, setbtnText] = useState("Update");
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({
    month: null,
    detailDate: null,
  });
  const [months, setMonths] = useState(null);

  const updateDate = (event) => {
    event.preventDefault();
    if (!formData.month && !formData.detailDate) {
      return alert("Please Select required fields");
    }
    setbtnText("Updating");
    setDisabled(true);
    api
      .post("/Home/detailsDate", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setbtnText("Updated");
          setTimeout(() => {
            setbtnText("Update");
            setDisabled(false);
          }, 2000);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        setbtnText("try again");
        setTimeout(() => {
          setbtnText("Update");
          setDisabled(false);
        }, 2000);
      });
  };

  useEffect(() => {
    (function () {
      api
        .get("/Home/detailsDate", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.data.type === "success") {
            setMonths(res.data.data);
          } else {
            setMonths(null);
          }
        })
        .catch((err) => {
          if (err.response.data.type === "error") {
            setToken(null);
            setIsLoggedin(false);
          }
          alert(err.response.data.message);
        });
    })();
  }, []);

  return (
    <Fragment>
      <div className="main">
        <div className="row">
          <form onSubmit={updateDate}>
            <div className="col-6">
              <div className="form-group">
                <label>Month</label>
                <select
                  className="form-control"
                  onChange={(e) => setFormData({ ...formData, month: e.target.value })}
                  value={formData.month}
                >
                  <option value="">Select Option</option>
                  {months &&
                    months.map((item, key) => (
                      <option key={key} value={item.month}>
                        {moment(item.month).format("MMM YYYY")}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) =>
                    setFormData({ ...formData, detailDate: e.target.value })
                  }
                  value={formData.detailDate}
                />
              </div>
              <div className="form-group mt-2">
                <input
                  type="submit"
                  className="btn btn-dark btn-sm"
                  value={btnText}
                  disabled={disabled}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default DetailsDate;
