import React, { useContext, createContext, useState, useEffect } from "react";
import api from "../axios/api";

const Context = createContext();

const LoginProvider = ({ children }) => {
  const [isLoggedin, setIsLoggedin] = useState(() => {
    const token = sessionStorage.getItem("encryptedData");
    return token !== null;
  });
  const [token, setToken] = useState(null);

  const userData = sessionStorage.getItem("encryptedData");

  const getProfile = () => {
    api
      .get("Home/validateLoggedin", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userData}`,
        },
      })
      .then((res) => {
        if (res.data.type !== "error") {
          setIsLoggedin(true);
        } else {
          setIsLoggedin(false);
        }
        if (res.data.status !== 200) {
          setIsLoggedin(false);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
      });
  };

  useEffect(() => {
    function checkLogin() {
      if (userData) {
        setToken(userData);
        setIsLoggedin(true);
        getProfile();
      }
    }
    window.addEventListener("storage", checkLogin());

    return () => {
      window.removeEventListener("storage", checkLogin());
    };
  }, []);

  return (
    <Context.Provider
      value={{
        isLoggedin,
        setIsLoggedin,
        token,
        setToken,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useLogin = () => useContext(Context);

export default LoginProvider;
