import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./../pages/Home";
import Details from "./../pages/Details";
import Login from "./../pages/admin/Login";
import { useLogin } from "./../context/Provider";
import AddHeading from "../pages/admin/AddHeading";
import AdminHome from "../pages/admin/AdminHome";
import Dashboard from "./../pages/admin/Dashboard";
import AddHeadingDetails from "./../pages/admin/AddHeadingDetails";
import EditHeading from "./../pages/admin/Edit/EditHeading";
import EditSubHeadingDetails from "./../pages/admin/Edit/EditSubHeadingDetails";
import NavbarImage from "./../pages/admin/GeneralSetting/NavbarImage";
import CustomHome from "./../pages/CustomHome";
import HomeImage from "./../pages/admin/GeneralSetting/HomeImage";
import Pdf from "./../pages/admin/GeneralSetting/Pdf";
import DetailsDate from "./../pages/admin/GeneralSetting/DetailsDate";
import CopyTemplate from "./../pages/admin/CopyTemplate/index";
import HomepageDate from "../pages/admin/GeneralSetting/HomepageDate";
import Password from "./../pages/admin/Password/index";

const Navigation = () => {
  const { isLoggedin } = useLogin();
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/details" element={<Details />} />
      <Route path="/year/:year/month/:month" element={<CustomHome />} />
      <Route path="/admin/login" element={<Login />} />
      <Route
        path="admin"
        element={isLoggedin ? <AdminHome /> : <Navigate replace to="/admin/login" />}
      >
        <Route index element={<Dashboard />} />
        <Route path="addHeading" element={<AddHeading />} />
        <Route path="addHeadingDetails" element={<AddHeadingDetails />} />
        <Route path="editHeading" element={<EditHeading />} />
        <Route path="editSubHeading" element={<EditSubHeadingDetails />} />
        <Route path="addNavbarImage" element={<NavbarImage />} />
        <Route path="addHomeImage" element={<HomeImage />} />
        <Route path="addPdf" element={<Pdf />} />
        <Route path="detailsDate" element={<DetailsDate />} />
        <Route path="homepageDate" element={<HomepageDate />} />
        <Route path="copy" element={<CopyTemplate />} />
        <Route path="changePassword" element={<Password />} />
      </Route>
    </Routes>
  );
};

export default Navigation;
