import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

import api from "./../axios/api";
import "./Home.scss";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let separator = "-";

  let detailsDate = `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;

  const [data, setData] = useState(null);
  const [homeImage, sethomeImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (function () {
      api
        .get(`Home/index`)
        .then((res) => {
          if (res.data.type === "success") {
            setData(res.data.data.heading);
            sethomeImage(res.data.data.homeImg);
            setLoading(false);
          } else {
            setData(null);
            sethomeImage(null);
            setLoading(false);
          }
        })
        .catch((err) => alert(err));
    })();
  }, []);

  if (loading) {
    return (
      <div className="spinner">
        <div className="container-fluid">
          <div className="center">
            <svg
              className="spinner"
              width="65px"
              height="65px"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="circle"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <nav className="navbar navbar-light">
        <div className="container-fluid">
          <a className="navbar-brand" onClick={toggleDrawer} href="javascript:void(0)">
            <span className="navbar-toggler-icon"></span>
          </a>
        </div>
      </nav>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        overlayOpacity="0"
        direction="left"
        className="drawer"
      >
        <div>
          <div className="title">
            <p>Schemes</p>
            <button
              type="button"
              className="btn-close"
              ariaLabel="Close"
              onClick={() => setIsOpen(false)}
            ></button>
          </div>
          <div className="dropdown-padding">
            <div className="accordion accordion-flush" id="accordionFlushExample">
              {data &&
                data.map((heading, key) => {
                  return (
                    <div className="accordion-item" key={key}>
                      <h2
                        className="accordion-header"
                        id={`flush-headingOne-${heading.id}`}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapseOne-${heading.id}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapseOne-${heading.id}`}
                        >
                          {heading.title}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapseOne-${heading.id}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`flush-headingOne-${heading.id}`}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          {heading.value &&
                            heading.value.map((subheading, key) => {
                              return (
                                <Fragment key={key}>
                                  <Link
                                    to="/details/"
                                    state={{
                                      title: `${subheading.title}`,
                                      hid: `${heading.id}`,
                                      shid: `${subheading.id}`,
                                      date: `${detailsDate}`,
                                    }}
                                  >
                                    <p className="m-0">{subheading.title}</p>
                                  </Link>
                                </Fragment>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Drawer>
      <div className="container-fluid p-0">
        <div className="center">
          <img src={homeImage && homeImage.value} />
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
