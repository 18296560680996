import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { useLogin } from "./../../context/Provider";
import { useState } from "react";
import api from "../../axios/api";

const Login = () => {
  const { setIsLoggedin } = useLogin();

  const [input, setInput] = useState({
    username: "",
    password: "",
  });

  const [btnText, setbtnText] = useState("Login");
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState({
    username: null,
    password: null,
  });

  const navigate = useNavigate();

  const formHandler = (e) => {
    e.preventDefault();
    setError({
      username: null,
      password: null,
    });
    setbtnText("Logging in...");
    setDisabled(true);
    const formData = new FormData();
    formData.append("username", input.username);
    formData.append("password", input.password);
    api
      .post("Home/login", formData)
      .then((res) => {
        if (res.data.type === "success") {
          sessionStorage.setItem("encryptedData", `Bearer ${res.data.data.token}`);
          setIsLoggedin(true);
          setTimeout(() => {
            setbtnText("Login");
            setDisabled(false);
            navigate("/admin");
          }, 500);
        } else if (res.data.type === "err") {
          alert(res.data.message);
          setbtnText("Login");
          setDisabled(false);
        } else {
          setError(res.data.message);
          setIsLoggedin(false);
          sessionStorage.removeItem("encryptedData");
          setbtnText("Login");
          setDisabled(false);
        }
      })
      .catch((err) => alert(err.response.message));
  };

  return (
    <div className="container-fluid bg">
      <div className="container">
        <div className="main">
          <div className="content">
            <h3>Admin Login</h3>
            <form onSubmit={formHandler}>
              <div className="form-group inputDiv">
                <label>Username</label>
                <input
                  type="text"
                  className="form-control"
                  value={input.username}
                  onChange={(e) => setInput({ ...input, username: e.target.value })}
                />
                {error.username && <span className="text-danger">{error.username}</span>}
              </div>
              <div className="form-group inputDiv">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  value={input.password}
                  onChange={(e) => setInput({ ...input, password: e.target.value })}
                />
                {error.password && <span className="text-danger">{error.password}</span>}
              </div>
              <div className="form-group text-center mt-4">
                <button type="submit" className="btn btn-dark w-100" disabled={disabled}>
                  {btnText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
