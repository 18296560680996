import React, { useState, Fragment, useEffect } from "react";
import api from "../../../axios/api";
import moment from "moment";
import { useLogin } from "./../../../context/Provider";

const HomepageDate = () => {
  const { token, setToken, setIsLoggedin } = useLogin();
  const [date, setDate] = useState();
  const [btnText, setbtnText] = useState("Change");
  const [disabled, setDisabled] = useState(false);
  const changeDate = (e) => {
    e.preventDefault();
    setbtnText("Please wait...");
    setDisabled(true);
    api
      .post(
        "/Home/homepageDate",
        { month: date },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        if (res.data.type === "success") {
          setbtnText("Changed");
          setTimeout(() => {
            setbtnText("Change");
            setDisabled(false);
          }, 2000);
        } else {
          setbtnText("Try Again");
          setTimeout(() => {
            setbtnText("Change");
            setDisabled(false);
          }, 2000);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        window.alert(err.response.data.message);
      });
  };

  useEffect(() => {
    (function () {
      api
        .get("/Home/homepageDate")
        .then((res) => {
          if (res.data.type === "success") {
            setDate(moment(res.data.data).format("YYYY-MM"));
          } else {
            window.alert("Something Went Wrong");
          }
        })
        .catch((err) => {
          window.alert(err.message);
        });
    })();
  }, []);

  return (
    <Fragment>
      <div className="main">
        <div className="row">
          <form onSubmit={changeDate}>
            <div className="col-6">
              <div className="form-group">
                <label>Homepage Month</label>
                <input
                  type="month"
                  className="form-control"
                  onChange={(e) => setDate(e.target.value)}
                  value={date}
                />
              </div>
              <div className="form-group mt-2">
                <input
                  type="submit"
                  className="btn btn-dark btn-sm"
                  value={btnText}
                  disabled={disabled}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default HomepageDate;
