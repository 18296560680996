import React, { Fragment, useState } from "react";
import api from "../../../axios/api";
import "./NavbarImage.scss";
import { useLogin } from "./../../../context/Provider";

const Pdf = () => {
  const { token, setToken, setIsLoggedin } = useLogin();
  let year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;
  let monthEdited = "";
  if (month.toString().length === 1) {
    monthEdited = `0${month}`;
  } else {
    monthEdited = month;
  }

  const [input, setInput] = useState(null);
  const [date, setDate] = useState(`${year}-${monthEdited}`);
  const [btnText, setbtnText] = useState("Update");
  const [disabled, setDisabled] = useState(false);

  const updatePdf = (event) => {
    event.preventDefault();
    setbtnText("Updating");
    setDisabled(true);
    const formData = new FormData();
    formData.append("pdf", input);
    formData.append("date", date);

    api
      .post("/Home/addPdf", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.data.type === "success") {
          setbtnText("Updated");
          setTimeout(() => {
            setbtnText("Update");
            setDisabled(false);
          }, 2000);
        } else {
          setbtnText("Try Again");
          setTimeout(() => {
            setbtnText("Update");
            setDisabled(false);
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        alert(error.response.data.message);
        setbtnText("Try Again");
        setTimeout(() => {
          setbtnText("Update");
          setDisabled(false);
        }, 2000);
      });
  };

  return (
    <Fragment>
      <div className="main">
        <div className="row">
          <form onSubmit={updatePdf}>
            <div className="col-6">
              <div className="form-group">
                <label>Upload Pdf</label>
                <input
                  type="file"
                  className="form-control"
                  accept="application/pdf"
                  onChange={(e) => setInput(e.target.files[0])}
                />
              </div>
              <div className="form-group">
                <label>Month</label>
                <input
                  type="month"
                  className="form-control"
                  onChange={(e) => setDate(e.target.value)}
                  value={date}
                />
              </div>
              <div className="form-group mt-2">
                <input
                  type="submit"
                  className="btn btn-dark btn-sm"
                  value={btnText}
                  disabled={disabled}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Pdf;
