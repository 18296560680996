import React, { useState, useEffect, Fragment } from "react";
import "./EditHeading.scss";
import api from "../../../axios/api";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useLogin } from "./../../../context/Provider";

const EditHeading = () => {
  const { token, setToken, setIsLoggedin } = useLogin();
  // date
  let year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;
  let monthEdited = "";
  if (month.toString().length === 1) {
    monthEdited = `0${month}`;
  } else {
    monthEdited = month;
  }

  // input
  const [input, setInput] = useState({
    month: `${year}-${monthEdited}`,
  });

  const [deleteTitle, setdeleteTitle] = useState(<DeleteOutlineOutlinedIcon />);

  const [selectedHeading, setSelectedHeading] = useState({
    id: "",
    title: "",
  });

  const [headingData, setHeadingData] = useState(null);

  const [btnTitle, setbtnTitle] = useState("Update");
  const [btnDisabled, setbtnDisabled] = useState(false);

  const getHeading = () => {
    const formData = new FormData();
    formData.append("date", input.month);
    api
      .post("Home/getHeading", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setHeadingData(res.data.data);
        } else {
          setHeadingData(null);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        setHeadingData(null);
      });
  };

  const updateHeading = (event) => {
    event.preventDefault();
    setbtnTitle("Updating..");
    setbtnDisabled(true);
    const formData = new FormData();
    formData.append("id", selectedHeading.id);
    formData.append("title", selectedHeading.title);
    api
      .post("Home/updateHeading", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          getHeading();
          setbtnTitle("Updated");
          setTimeout(() => {
            setbtnTitle("Update");
            setbtnDisabled(false);
          }, 2000);
        } else {
          setbtnTitle("Please Try Again");
          setbtnDisabled(false);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        setbtnTitle("Please Try Again");
        setbtnDisabled(false);
      });
  };

  const handleHeadingDelete = (id) => {
    if (window.confirm("Are you Sure?")) {
      setdeleteTitle("Deleteing..");
      const formData = new FormData();
      formData.append("id", id);
      api
        .post("Home/deleteHeading", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.data.type === "success") {
            setdeleteTitle("Deleted");
            getHeading();
            setTimeout(() => {
              setdeleteTitle(<DeleteOutlineOutlinedIcon />);
            }, 2000);
          } else {
            setdeleteTitle("Please Try Again");
            setTimeout(() => {
              setdeleteTitle(<DeleteOutlineOutlinedIcon />);
            }, 2000);
          }
        })
        .catch((err) => {
          if (err.response.data.type === "error") {
            setToken(null);
            setIsLoggedin(false);
          }
          setdeleteTitle("Please Try Again");
          setTimeout(() => {
            setdeleteTitle(<DeleteOutlineOutlinedIcon />);
          }, 2000);
        });
    }
  };

  const updateHeadingPriority = (index) => (e) => {
    let newArr = [...headingData];
    const priority = e.target.value;
    const id = newArr[index].id;
    const formData = new FormData();
    formData.append("id", id);
    formData.append("priority", priority);
    api
      .post("Home/updateHeadingPriority", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          newArr[index].priority = priority;
          setHeadingData(newArr);
        } else {
          window.alert(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        window.alert(err.response.data.message);
      });
  };

  // edit subheading
  const [subInput, setSubInput] = useState({
    month: `${year}-${monthEdited}`,
    title: "",
    parent_id: "",
  });
  // heading dropdown
  const [subHeading, setSubHeading] = useState(null);

  // table row
  const [selectedId, setSelectedId] = useState();
  // sub_title state
  const [description, setDescription] = useState("");
  // description button
  const [descriptionButton, setdescriptionButton] = useState("Update");

  // table edit
  const [selectedSubHeading, setSelectedSubHeading] = useState({
    id: "",
    title: "",
  });

  const [subBtnDisabled, setSubBtnDisabled] = useState();
  const [subBtnTitle, setSubBtnTitle] = useState("Update");
  const [deleteSubTitle, setdeleteSubTitle] = useState(<DeleteOutlineOutlinedIcon />);

  const getSubHeading = () => {
    const formData = new FormData();
    formData.append("date", subInput.month);
    formData.append("heading_id", subInput.parent_id);
    api
      .post("Home/getSubHeading", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setSubHeading(res.data.data);
        } else if (res.data.type === "error") {
          setSubHeading(null);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        alert(err);
      });
  };

  const updateSubHeading = (event) => {
    event.preventDefault();
    setSubBtnTitle("Updating..");
    setSubBtnDisabled(true);
    const formData = new FormData();
    formData.append("id", selectedSubHeading.id);
    formData.append("title", selectedSubHeading.title);
    api
      .post("Home/updateSubHeading", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          getSubHeading();
          setSubBtnTitle("Updated");
          setTimeout(() => {
            setSubBtnTitle("Update");
            setSubBtnDisabled(false);
          }, 2000);
        } else {
          setSubBtnTitle("Please Try Again");
          setSubBtnDisabled(false);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        setSubBtnTitle("Please Try Again");
        setSubBtnDisabled(false);
      });
  };

  const handleSubHeadingDelete = (id) => {
    if (window.confirm("Are you Sure?")) {
      setdeleteSubTitle("Deleteing..");
      const formData = new FormData();
      formData.append("id", id);
      api
        .post("Home/deleteSubHeading", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.data.type === "success") {
            setdeleteSubTitle("Deleted");
            getSubHeading();
            setTimeout(() => {
              setdeleteSubTitle(<DeleteOutlineOutlinedIcon />);
            }, 2000);
          } else {
            setdeleteSubTitle("Please Try Again");
            setTimeout(() => {
              setdeleteSubTitle(<DeleteOutlineOutlinedIcon />);
            }, 2000);
          }
        })
        .catch((err) => {
          if (err.response.data.type === "error") {
            setToken(null);
            setIsLoggedin(false);
          }
          setdeleteSubTitle("Please Try Again");
          setTimeout(() => {
            setdeleteSubTitle(<DeleteOutlineOutlinedIcon />);
          }, 2000);
        });
    }
  };

  const updateDescription = () => {
    setdescriptionButton("Updating");
    const formData = new FormData();
    formData.append("id", selectedId);
    formData.append("sub_title", description);

    api
      .post("Home/updateDescription", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          getSubHeading();
          setdescriptionButton("Updated");
          setTimeout(() => {
            setdescriptionButton("Update");
          }, 2000);
        } else {
          setdescriptionButton("Try Again");
          setTimeout(() => {
            setdescriptionButton("Update");
          }, 2000);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        setdescriptionButton("Try Again");
        setTimeout(() => {
          setdescriptionButton("Update");
        }, 2000);
      });
  };

  const updateSubheadingPriority = (index) => (e) => {
    let newArr = [...subHeading];
    const priority = e.target.value;
    const id = newArr[index].id;
    const formData = new FormData();
    formData.append("id", id);
    formData.append("priority", priority);
    api
      .post("Home/updateHeadingPriority", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          newArr[index].priority = priority;
          setSubHeading(newArr);
        } else {
          window.alert(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        window.alert(err.response.data.message);
      });
  };

  useEffect(() => {
    getHeading();
  }, []);

  useEffect(() => {
    getHeading();
  }, [input.month, subInput.month]);

  useEffect(() => {
    getSubHeading();
  }, [subInput.parent_id]);

  return (
    <Fragment>
      <div className="main">
        <div className="row">
          <div className="col-6">
            <h3>Edit Heading</h3>
            <div className="form">
              <div className="form-group inputDiv">
                <label>Month</label>
                <input
                  type="month"
                  value={input.month}
                  onChange={(e) => setInput({ ...input, month: e.target.value })}
                  className="form-control"
                />
              </div>
              <table className="table text-center">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Priority</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {headingData ? (
                    headingData.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{item.title}</td>
                          <td>
                            <select
                              className="form-control"
                              value={item.priority}
                              name="name"
                              onChange={updateHeadingPriority(key)}
                            >
                              <option
                                value="0"
                                {...(item.priority === 0 ? "selected" : "")}
                              >
                                0
                              </option>
                              {Array.from(Array(headingData.length).keys()).map(
                                (i, key) => (
                                  <option key={key} value={i + 1}>
                                    {i + 1}
                                  </option>
                                )
                              )}
                            </select>
                          </td>
                          <td>
                            <button
                              className="btn btn-light btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={() => {
                                setSelectedHeading({ id: item.id, title: item.title });
                              }}
                            >
                              <EditOutlinedIcon />
                            </button>
                            <button
                              className="btn btn-danger btn-sm ms-1"
                              onClick={() => handleHeadingDelete(item.id)}
                            >
                              {deleteTitle}
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <Fragment>
                      <tr className="text-center">
                        <td colSpan={4}>No Data Found</td>
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6">
            <h3>Edit Subheading</h3>
            <div className="form">
              <div className="form-group inputDiv">
                <label>Month</label>
                <input
                  type="month"
                  value={subInput.month}
                  onChange={(e) => setSubInput({ ...subInput, month: e.target.value })}
                  className="form-control"
                />
              </div>
              <div className="form-group inputDiv">
                <label>Heading</label>
                <select
                  className="form-control"
                  value={subInput.parent_id}
                  onChange={(e) =>
                    setSubInput({ ...subInput, parent_id: e.target.value })
                  }
                >
                  <option value="">Select Heading</option>
                  {headingData ? (
                    headingData.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.title}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">No Heading Found</option>
                  )}
                </select>
              </div>
              <table className="table table-center">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Priority</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {subHeading ? (
                    subHeading.map((item, key) => {
                      return (
                        <tr
                          className={item.id === selectedId ? "back" : ""}
                          key={key}
                          onClick={() => {
                            setSelectedId(item.id);
                            setDescription(subHeading[key].sub_title);
                          }}
                        >
                          <td>{key + 1}</td>
                          <td>{item.title}</td>
                          <td>
                            <select
                              className="form-control"
                              value={item.priority}
                              name="name"
                              onChange={updateSubheadingPriority(key)}
                            >
                              <option value="0">0</option>
                              {Array.from(Array(subHeading.length).keys()).map(
                                (i, key) => (
                                  <option key={key} value={i + 1}>
                                    {i + 1}
                                  </option>
                                )
                              )}
                            </select>
                          </td>
                          <td>
                            <button
                              className="btn btn-light btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal1"
                              onClick={() => {
                                setSelectedSubHeading({ id: item.id, title: item.title });
                              }}
                            >
                              <EditOutlinedIcon />
                            </button>
                            <button
                              className="btn btn-danger btn-sm ms-1"
                              onClick={() => handleSubHeadingDelete(item.id)}
                            >
                              {deleteSubTitle}
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <Fragment>
                      <tr className="text-center">
                        <td colSpan={4}>No Data Found</td>
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </table>
              <div className="form-group inputDiv">
                <label>Subheading Description</label>
                <textarea
                  cols="30"
                  rows="5"
                  className="form-control"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="form-group text-end">
                <button className="btn btn-dark btn-sm" onClick={updateDescription}>
                  {descriptionButton}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit {selectedHeading.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setSelectedHeading({ id: "", title: "" })}
              ></button>
            </div>
            <form onSubmit={updateHeading}>
              <div className="modal-body">
                <div className="form-group">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedHeading.title}
                    onChange={(e) =>
                      setSelectedHeading({ ...selectedHeading, title: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-dark btn-sm"
                  disabled={btnDisabled}
                >
                  {btnTitle}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Subheading {selectedSubHeading.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={updateSubHeading}>
              <div className="modal-body">
                <div className="form-group">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedSubHeading.title}
                    onChange={(e) =>
                      setSelectedSubHeading({
                        ...selectedSubHeading,
                        title: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-dark btn-sm"
                  disabled={subBtnDisabled}
                >
                  {subBtnTitle}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditHeading;
