import React, { Fragment, useState } from "react";
import api from "../../../axios/api";
import { useLogin } from "./../../../context/Provider";

const Password = () => {
  const { token, setToken, setIsLoggedin } = useLogin();

  const [input, setInput] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [btnText, setBtnText] = useState("Change");
  const [disabled, setDisable] = useState(false);

  const [error, setError] = useState({
    confirmNewPassword: null,
    currentPassword: null,
    newPassword: null,
  });

  const changePassword = (e) => {
    e.preventDefault();
    setBtnText("Changing");
    setDisable(true);
    setError({
      confirmNewPassword: null,
      currentPassword: null,
      newPassword: null,
    });
    api
      .post("/Home/changePassword", input, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setInput({
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          });
          setBtnText("Changed");
        } else if (res.data.type === "err") {
          alert(res.data.message);
          setBtnText("Try Again");
        } else {
          setError(res.data.message);
          setBtnText("Try Again");
        }
        setTimeout(() => {
          setBtnText("Change");
          setDisable(false);
        }, 2000);
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        setBtnText("Try Again");
        setTimeout(() => {
          setBtnText("Change");
          setDisable(false);
        }, 2000);
      });
  };

  return (
    <Fragment>
      <div className="main">
        <div className="row">
          <form onSubmit={changePassword}>
            <div className="col-6">
              <div className="form-group">
                <label>Current Password</label>
                <input
                  className="form-control"
                  type="password"
                  value={input.currentPassword}
                  onChange={(e) =>
                    setInput({ ...input, currentPassword: e.target.value })
                  }
                />
                {error.currentPassword && (
                  <span className="text-danger">{error.currentPassword}</span>
                )}
              </div>
              <div className="form-group">
                <label>New Password</label>
                <input
                  className="form-control"
                  type="password"
                  value={input.newPassword}
                  onChange={(e) => setInput({ ...input, newPassword: e.target.value })}
                />
                {error.newPassword && (
                  <span className="text-danger">{error.newPassword}</span>
                )}
              </div>
              <div className="form-group">
                <label>Confirm New Password</label>
                <input
                  className="form-control"
                  type="password"
                  value={input.confirmNewPassword}
                  onChange={(e) =>
                    setInput({ ...input, confirmNewPassword: e.target.value })
                  }
                />
                {error.confirmNewPassword && (
                  <span className="text-danger">{error.confirmNewPassword}</span>
                )}
              </div>

              <div className="form-group mt-2">
                <input
                  type="submit"
                  className="btn btn-dark btn-sm"
                  value={btnText}
                  disabled={disabled}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Password;
