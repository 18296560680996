import React, { Fragment, useState, useEffect } from "react";
import api from "../../axios/api";
import { Editor } from "@tinymce/tinymce-react";
import { useLogin } from "./../../context/Provider";

const AddHeadingDetails = () => {
  const { token, setToken, setIsLoggedin } = useLogin();
  // Add Subheading
  let year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;
  let monthEdited = "";
  if (month.toString().length === 1) {
    monthEdited = `0${month}`;
  } else {
    monthEdited = month;
  }

  const [input, setInput] = useState({
    month: `${year}-${monthEdited}`,
    heading_id: "",
    subHeading_id: "",
    title: "",
    size: "",
  });

  const [heading, setHeading] = useState(null);
  const [subHeading, setSubHeading] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnTitle, setBtnTitle] = useState("Add Subheading Details");
  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnDisabled(true);
    setBtnTitle("Adding");
    const formData = new FormData();
    formData.append("month", input.month);
    formData.append("heading_id", input.heading_id);
    formData.append("subHeading_id", input.subHeading_id);
    formData.append("title", input.title);
    formData.append("size", input.size);
    api
      .post("Home/addParentDetails", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setInput({
            month: `${year}-${monthEdited}`,
            heading_id: "",
            subHeading_id: "",
            title: "",
            size: "",
          });
          setBtnTitle("Added");
          setTimeout(() => {
            setBtnTitle("Add Subheading Details");
            setBtnDisabled(false);
          }, 2000);
        } else {
          setBtnTitle("Add Subheading Details");
          setBtnDisabled(false);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
      });
  };

  useEffect(() => {
    (function () {
      const formData = new FormData();
      formData.append("date", input.month);
      formData.append("heading_id", input.heading_id);
      api
        .post("Home/getSubHeading", formData)
        .then((res) => {
          if (res.data.type === "success") {
            setSubHeading(res.data.data);
          } else if (res.data.type === "error") {
            setSubHeading(res.data.data);
          }
        })
        .catch((err) => {
          alert(err);
        });
    })();
  }, [input.heading_id]);

  // Add Dropdown

  const [childInput, setChildInput] = useState({
    heading_id: "",
    subHeading_id: "",
    parent_id: "",
    data: "",
  });

  const [dropdownBtnDisabled, setDropdownBtnDisabled] = useState(false);
  const [dropdownBtnTitle, setDropdownBtnTitle] = useState("Add DropDown Details");
  const [dropdownHeading, setDropdownHeading] = useState(null);
  const [dropdownSubHeading, setDropdownSubHeading] = useState(null);
  const [dropdownParent, setDropdownParent] = useState(null);

  const handleDropdownSubmit = (event) => {
    event.preventDefault();
    setDropdownBtnDisabled(true);
    setDropdownBtnTitle("Adding");
    const formData = new FormData();
    formData.append("parent_id", childInput.parent_id);
    formData.append("data", childInput.data);
    api
      .post("Home/addChildDetails", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setChildInput({
            heading_id: "",
            subHeading_id: "",
            parent_id: "",
            data: "",
          });
          setDropdownBtnTitle("Added");
          setTimeout(() => {
            setDropdownBtnTitle("Add Subheading Details");
            setDropdownBtnDisabled(false);
          }, 2000);
        } else {
          setDropdownBtnTitle("Add Subheading Details");
          setDropdownBtnDisabled(false);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
      });
  };

  useEffect(() => {
    (function () {
      const formData = new FormData();
      formData.append("date", input.month);
      formData.append("heading_id", childInput.heading_id);
      api
        .post("Home/getSubHeading", formData)
        .then((res) => {
          if (res.data.type === "success") {
            setDropdownSubHeading(res.data.data);
          } else if (res.data.type === "error") {
            setDropdownSubHeading(res.data.data);
          }
        })
        .catch((err) => {
          alert(err);
        });
    })();
  }, [childInput.heading_id]);

  useEffect(() => {
    (function () {
      const formData = new FormData();
      formData.append("date", input.month);
      formData.append("heading_id", childInput.heading_id);
      formData.append("subHeading_id", childInput.subHeading_id);
      api
        .post("Home/getParentDetails", formData)
        .then((res) => {
          if (res.data.type === "success") {
            setDropdownParent(res.data.data);
          } else if (res.data.type === "error") {
            setDropdownParent(res.data.data);
          }
        })
        .catch((err) => {
          alert(err);
        });
    })();
  }, [childInput.subHeading_id]);

  // Common

  useEffect(() => {
    (function () {
      const formData = new FormData();
      formData.append("date", input.month);
      api
        .post("Home/getHeading", formData)
        .then((res) => {
          if (res.data.type === "success") {
            setHeading(res.data.data);
            setDropdownHeading(res.data.data);
          } else if (res.data.type === "error") {
            setHeading(res.data.data);
            setDropdownHeading(res.data.data);
          }
        })
        .catch((err) => {
          alert(err);
        });
    })();
  }, [input.month, childInput.month]);

  return (
    <Fragment>
      <div className="main">
        <div className="row">
          <div className="col-6">
            <h3>Add Subheading Details</h3>
            <div className="form">
              <form onSubmit={handleSubmit}>
                <div className="form-group inputDiv">
                  <label>Month</label>
                  <input
                    type="month"
                    className="form-control"
                    value={input.month}
                    onChange={(e) => setInput({ ...input, month: e.target.value })}
                  />
                </div>
                <div className="form-group inputDiv">
                  <label>Heading Title</label>
                  <select
                    className="form-control"
                    value={input.heading_id}
                    onChange={(e) => setInput({ ...input, heading_id: e.target.value })}
                  >
                    <option value="">Select Heading</option>
                    {heading &&
                      heading.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {item.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="form-group inputDiv">
                  <label>SubHeading Title</label>
                  <select
                    className="form-control"
                    value={input.subHeading_id}
                    onChange={(e) =>
                      setInput({ ...input, subHeading_id: e.target.value })
                    }
                  >
                    <option value="">Select SubHeading</option>
                    {subHeading &&
                      subHeading.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {item.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="form-group inputDiv">
                  <label>Subheading Details Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={input.title}
                    onChange={(e) => setInput({ ...input, title: e.target.value })}
                  />
                </div>
                <div className="form-group inputDiv">
                  <label>Size</label>
                  <select
                    className="form-control"
                    value={input.size}
                    onChange={(e) => setInput({ ...input, size: e.target.value })}
                  >
                    <option value="">Select Size</option>
                    <option value="12">1</option>
                    <option value="8">2</option>
                    <option value="4">3</option>
                  </select>
                </div>
                <div className="form-group inputDiv text-end">
                  <button
                    type="submit"
                    disabled={btnDisabled}
                    className="btn btn-sm btn-dark"
                  >
                    {btnTitle}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <form onSubmit={handleDropdownSubmit}>
            <div className="col-6">
              <h3>Add Dropdown Details</h3>
              <div className="form">
                <div className="form-group inputDiv">
                  <label>Month</label>
                  <input
                    type="month"
                    className="form-control"
                    value={input.month}
                    onChange={(e) => setInput({ ...input, month: e.target.value })}
                  />
                </div>
                <div className="form-group inputDiv">
                  <label>Heading Title</label>
                  <select
                    className="form-control"
                    value={childInput.heading_id}
                    onChange={(e) =>
                      setChildInput({ ...childInput, heading_id: e.target.value })
                    }
                  >
                    <option value="">Select Heading</option>
                    {dropdownHeading &&
                      dropdownHeading.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {item.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="form-group inputDiv">
                  <label>SubHeading Title</label>
                  <select
                    className="form-control"
                    value={childInput.subHeading_id}
                    onChange={(e) =>
                      setChildInput({ ...childInput, subHeading_id: e.target.value })
                    }
                  >
                    <option value="">Select SubHeading</option>
                    {dropdownSubHeading &&
                      dropdownSubHeading.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {item.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="form-group inputDiv">
                  <label>SubHeading Title</label>
                  <select
                    value={childInput.parent_id}
                    onChange={(e) =>
                      setChildInput({ ...childInput, parent_id: e.target.value })
                    }
                    className="form-control"
                  >
                    <option value="">Select SubHeading Title</option>
                    {dropdownParent &&
                      dropdownParent.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {item.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
            <div className="editor">
              <span className="text-danger">*drag & drop image to text editor</span>
              <Editor
                apiKey="3afezbf934b4ikf5582aszjwd035bb5vdu4qcnl2ke5kch7e"
                init={{
                  height: 500,
                  menubar: true,
                  image_title: true,
                  file_picker_types: "image",
                  plugins: "link image table code",
                  // plugins: [
                  //   "advlist autolink link image code charmap paste print preview anchor autoresize",
                  //   "searchreplace visualblocks code fullscreen",
                  //   "image",
                  //   "table",
                  //   "insertdatetime media table paste code help wordcount",
                  // ],
                  // toolbar:
                  //   "undo redo | formatselect | " +
                  //   "bold italic backcolor | alignleft aligncenter " +
                  //   "alignright alignjustify | bullist numlist outdent indent | " +
                  //   "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  table_class_list: [
                    { title: "None", value: "" },
                    { title: "Bottom Border", value: "table-bottom" },
                  ],
                  link_assume_external_targets: true,
                }}
                onChange={(e) =>
                  setChildInput({ ...childInput, data: e.target.getContent() })
                }
              />
            </div>
            <div className="form-group mt-3 ">
              <button type="submit" className="btn btn-sm btn-dark">
                {dropdownBtnTitle}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddHeadingDetails;
