import React from "react";
import { BrowserRouter } from "react-router-dom";
import LoginProvider from "./context/Provider";

import Navigation from "./routes/Routes";

function App() {
  return (
    <LoginProvider>
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </LoginProvider>
  );
}

export default App;
