import React, { Fragment, useState, useEffect } from "react";
import api from "../../../axios/api";
import cloudinary from "../../../axios/cloudinary";
import "./NavbarImage.scss";

const NavbarImage = () => {
  const [input, setInput] = useState(null);
  const [preview, setPreview] = useState(null);
  const [btnText, setbtnText] = useState("Update");
  const [disabled, setDisabled] = useState(false);

  const updateImage = (event) => {
    event.preventDefault();
    setbtnText("Updating");
    setDisabled(true);
    const formData = new FormData();
    formData.append("file", input);
    formData.append("upload_preset", "lsa0wiqg");

    cloudinary
      .post("upload", formData)
      .then((res) => {
        if (res.status === 200) {
          const form = new FormData();
          form.append("img", res.data.url);
          api
            .post("/Home/addNavbarImage", form)
            .then((response) => {
              if (response.data.type === "success") {
                setbtnText("Updated");
                setTimeout(() => {
                  setbtnText("Update");
                  setDisabled(false);
                }, 2000);
              } else {
                setbtnText("Try Again");
                setTimeout(() => {
                  setbtnText("Update");
                  setDisabled(false);
                }, 2000);
              }
            })
            .catch((error) => {
              alert(error);
              setbtnText("Try Again");
              setTimeout(() => {
                setbtnText("Update");
                setDisabled(false);
              }, 2000);
            });
        }
      })
      .catch((err) => {
        alert(err);
        setbtnText("Try Again");
        setTimeout(() => {
          setbtnText("Update");
          setDisabled(false);
        }, 2000);
      });
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setInput(undefined);
      return;
    }

    setInput(e.target.files[0]);
  };

  useEffect(() => {
    if (!input) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(input);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [input]);

  return (
    <Fragment>
      <div className="main">
        <div className="row">
          <form onSubmit={updateImage}>
            <div className="col-6">
              <div className="form-group">
                <label>Add Navbar Image</label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={onSelectFile}
                />
              </div>
              <div className="cen my-2">
                {input && <img src={preview} height="100" alt="image" />}
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  className="btn btn-dark btn-sm"
                  value={btnText}
                  disabled={disabled}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default NavbarImage;
