import React, { useState, Fragment, useEffect } from "react";
import api from "../../../axios/api";
import moment from "moment";
import { useLogin } from "./../../../context/Provider";

const CopyTemplate = () => {
  const { token, setToken, setIsLoggedin } = useLogin();

  const [btnText, setbtnText] = useState("Copy");
  const [disabled, setDisabled] = useState(false);
  const [months, setMonths] = useState(null);
  const [input, setInput] = useState({
    from: "",
    to: null,
  });

  const copyTemplate = (event) => {
    event.preventDefault();
    setbtnText("Please Wait...");
    setDisabled(true);
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    api
      .post("/Home/copyTemplate", input, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setbtnText("Copied Successfully");
          setTimeout(() => {
            setbtnText("Copy");
            setDisabled(false);
          }, 2000);
        } else {
          setbtnText("Something Went Wrong");
          setTimeout(() => {
            setbtnText("Copy");
            setDisabled(false);
          }, 2000);
        }
        window.alert(res.data.message);
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        setDisabled(false);
      });
  };

  useEffect(() => {
    (function () {
      api
        .get("/Home/detailsDate", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.data.type === "success") {
            setMonths(res.data.data);
          } else {
            setMonths(null);
          }
        })
        .catch((err) => {
          if (err.response.data.type === "error") {
            setToken(null);
            setIsLoggedin(false);
          }
        });
    })();
  }, []);

  return (
    <Fragment>
      <div className="main">
        <div className="row">
          <form onSubmit={copyTemplate}>
            <div className="col-6">
              <div className="form-group">
                <label>From</label>
                <select
                  className="form-control"
                  onChange={(e) => setInput({ ...input, from: e.target.value })}
                  value={input.from}
                >
                  <option value="">Select Option</option>
                  {months &&
                    months.map((item, key) => (
                      <option key={key} value={item.month}>
                        {moment(item.month).format("MMM YYYY")}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <label>To</label>
                <input
                  type="month"
                  className="form-control"
                  onChange={(e) => setInput({ ...input, to: e.target.value })}
                  value={input.detailDate}
                />
              </div>
              <div className="form-group mt-2">
                <input
                  type="submit"
                  className="btn btn-dark btn-sm"
                  value={btnText}
                  disabled={disabled}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default CopyTemplate;
