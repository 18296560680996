import React, { useState, useEffect, Fragment } from "react";
import api from "../../../axios/api";
import { Editor } from "@tinymce/tinymce-react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import "./EditSubHeadingDetails.scss";
import { useLogin } from "./../../../context/Provider";

const EditSubHeadingDetails = () => {
  const { token, setToken, setIsLoggedin } = useLogin();
  // Add Subheading
  let year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;
  let monthEdited = "";
  if (month.toString().length === 1) {
    monthEdited = `0${month}`;
  } else {
    monthEdited = month;
  }

  const [input, setInput] = useState({
    month: `${year}-${monthEdited}`,
    heading_id: "",
    subHeading_id: "",
    title: "",
    size: "",
    dropdownDetails: "",
    data: "",
  });

  const [data, setData] = useState({
    id: "",
    data: "",
  });

  const [heading, setHeading] = useState(null);
  const [subHeading, setSubHeading] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [modalTitle, setModalTitle] = useState("Update");
  const [dropdown, setDropdown] = useState(null);
  const [deleteTitle, setdeleteTitle] = useState(<DeleteOutlineOutlinedIcon />);
  const [selected, setSelected] = useState({
    id: "",
    title: "",
    size: "",
  });
  const [row, setRow] = useState(null);
  const [btnUpdate, setbtnUpdate] = useState("Update");
  const [btnDelete, setbtnDelete] = useState("Delete");

  const getParentDetails = () => {
    const formData = new FormData();
    formData.append("date", input.month);
    formData.append("heading_id", input.heading_id);
    formData.append("subHeading_id", input.subHeading_id);
    api
      .post("Home/getParentDetails", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setDropdown(res.data.data);
        } else if (res.data.type === "error") {
          setDropdown(null);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        setDropdown(null);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnDisabled(true);
    setModalTitle("Updating");
    const formData = new FormData();
    formData.append("id", selected.id);
    formData.append("title", selected.title);
    formData.append("size", selected.size);
    api
      .post("Home/updateParentDetails", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          getParentDetails();
          setSelected({
            id: "",
            title: "",
            size: "",
          });
          setModalTitle("Updated");
          setTimeout(() => {
            setModalTitle("Update");
            setBtnDisabled(false);
          }, 2000);
        } else {
          setModalTitle("Try Again");
          setTimeout(() => {
            setModalTitle("Update");
            setBtnDisabled(false);
          }, 2000);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        setModalTitle("Try Again");
        setTimeout(() => {
          setModalTitle("Update");
          setBtnDisabled(false);
        }, 2000);
      });
  };

  const deleteDropdown = (id) => {
    if (window.confirm("Are you Sure?")) {
      setdeleteTitle("Deleteing..");
      const formData = new FormData();
      formData.append("id", id);
      api
        .post("Home/deleteParentDetails", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.data.type === "success") {
            getParentDetails();
            setdeleteTitle("Deleted");
            setTimeout(() => {
              setdeleteTitle(<DeleteOutlineOutlinedIcon />);
            }, 2000);
          } else {
            setdeleteTitle("Try again");
            setTimeout(() => {
              setdeleteTitle(<DeleteOutlineOutlinedIcon />);
            }, 2000);
          }
        })
        .catch((err) => {
          if (err.response.data.type === "error") {
            setToken(null);
            setIsLoggedin(false);
          }
          setdeleteTitle("Try again");
          setTimeout(() => {
            setdeleteTitle(<DeleteOutlineOutlinedIcon />);
          }, 2000);
        });
    }
  };

  const selectedRow = (id) => {
    setRow(id);
    const formData = new FormData();
    formData.append("id", id);
    api
      .post("Home/getInnerPageSubheading", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setData({
            ...data,
            data: res.data.data[0].data,
            id: res.data.data[0].id,
          });
        } else if (res.data.type === "error") {
          window.alert("something went wrong");
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        alert(err);
      });
  };

  const updateChildDetails = () => {
    setBtnDisabled(true);
    setbtnUpdate("Updating...");
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("data", data.data);
    api
      .post("Home/updateChildDetails", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setbtnUpdate("Updated");
          setTimeout(() => {
            setbtnUpdate("Update");
            setBtnDisabled(false);
          }, 2000);
        } else if (res.data.type === "error") {
          window.alert("something went wrong");
          setbtnUpdate("Update");
          setBtnDisabled(false);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        window.alert(err.response.data.message);
        setbtnUpdate("Update");
        setBtnDisabled(false);
      });
  };

  const deleteChildDetails = () => {
    setBtnDisabled(true);
    setbtnDelete("Deleting...");
    const formData = new FormData();
    formData.append("id", data.id);
    api
      .post("Home/deleteChildDetails", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setbtnDelete("Deleted");
          setTimeout(() => {
            setbtnDelete("Delete");
            setBtnDisabled(false);
          }, 2000);
        } else if (res.data.type === "error") {
          window.alert("something went wrong");
          setbtnDelete("Delete");
          setBtnDisabled(false);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        window.alert(err.response.data.message);
        setbtnDelete("Delete");
        setBtnDisabled(false);
      });
  };

  const updatePriority = (index) => (e) => {
    let newArr = [...dropdown];
    const priority = e.target.value;
    const id = newArr[index].id;

    const formData = new FormData();
    formData.append("id", id);
    formData.append("priority", priority);
    api
      .post("Home/updatePriority", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          newArr[index].priority = priority;
          setDropdown(newArr);
        } else {
          window.alert(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        window.alert(err.response.data.message);
      });
  };

  useEffect(() => {
    (function () {
      const formData = new FormData();
      formData.append("date", input.month);
      formData.append("heading_id", input.heading_id);
      api
        .post("Home/getSubHeading", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.data.type === "success") {
            setSubHeading(res.data.data);
          } else if (res.data.type === "error") {
            setSubHeading(res.data.data);
            setDropdown(null);
          }
        })
        .catch((err) => {
          if (err.response.data.type === "error") {
            setToken(null);
            setIsLoggedin(false);
          }
        });
    })();
  }, [input.heading_id]);

  useEffect(() => {
    getParentDetails();
  }, [input.subHeading_id]);

  useEffect(() => {
    (function () {
      const formData = new FormData();
      formData.append("date", input.month);
      api
        .post("Home/getHeading", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.data.type === "success") {
            setHeading(res.data.data);
          } else if (res.data.type === "error") {
            setHeading(res.data.data);
          }
        })
        .catch((err) => {
          if (err.response.data.type === "error") {
            setToken(null);
            setIsLoggedin(false);
          }
        });
    })();
  }, [input.month]);

  return (
    <Fragment>
      <div className="main">
        <div className="row">
          <div className="col-8">
            <h3>Edit Subheading Details</h3>
            <div className="form">
              <div className="form-group inputDiv">
                <label>Month</label>
                <input
                  type="month"
                  className="form-control"
                  value={input.month}
                  onChange={(e) => setInput({ ...input, month: e.target.value })}
                />
              </div>
              <div className="form-group inputDiv">
                <label>Heading Title</label>
                <select
                  className="form-control"
                  value={input.heading_id}
                  onChange={(e) => setInput({ ...input, heading_id: e.target.value })}
                >
                  <option value="">Select Heading Title</option>
                  {heading &&
                    heading.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.title}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group inputDiv">
                <label>SubHeading Title</label>
                <select
                  className="form-control"
                  value={input.subHeading_id}
                  onChange={(e) => setInput({ ...input, subHeading_id: e.target.value })}
                >
                  <option value="">Select SubHeading Title</option>
                  {subHeading &&
                    subHeading.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.title}
                        </option>
                      );
                    })}
                </select>
              </div>
              <table className="table text-center">
                <thead>
                  <th>#</th>
                  <th>Title</th>
                  <th>Size</th>
                  <th>Priority</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {dropdown ? (
                    dropdown.map((item, key) => {
                      return (
                        <tr
                          key={key}
                          className={item.id === row ? "back" : ""}
                          onClick={() => selectedRow(item.id)}
                        >
                          <td>{key + 1}</td>
                          <td>{item.title}</td>
                          <td>
                            {item.size === "12"
                              ? "1"
                              : item.size === "4"
                              ? "3"
                              : item.size === "6"
                              ? "2"
                              : ""}
                          </td>
                          <td>
                            <select
                              className="form-control"
                              value={item.priority}
                              name="name"
                              onChange={updatePriority(key)}
                            >
                              <option
                                value="0"
                                {...(item.priority === 0 ? "selected" : "")}
                              >
                                0
                              </option>
                              {Array.from(Array(heading.length).keys()).map((i, key) => (
                                <option key={key} value={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <button
                              className="btn btn-sm btn-light"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={() =>
                                setSelected({
                                  id: item.id,
                                  title: item.title,
                                  size: item.size,
                                })
                              }
                            >
                              <EditOutlinedIcon />
                            </button>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => deleteDropdown(item.id)}
                            >
                              {deleteTitle}
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan={5}>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="editor">
            <Editor
              apiKey="3afezbf934b4ikf5582aszjwd035bb5vdu4qcnl2ke5kch7e"
              init={{
                height: 500,
                menubar: true,
                image_title: true,
                file_picker_types: "image",
                plugins: "link image table code",
                // plugins: [
                //   "advlist autolink lists link image charmap print preview anchor autoresize",
                //   "searchreplace visualblocks code fullscreen",
                //   "table",
                //   "insertdatetime media table paste code help wordcount",
                // ],
                // toolbar:
                //   "undo redo | formatselect | " +
                //   "bold italic backcolor | alignleft aligncenter " +
                //   "alignright alignjustify | bullist numlist outdent indent | " +
                //   "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                table_class_list: [
                  { title: "None", value: "" },
                  { title: "Bottom Border", value: "table-bottom" },
                ],
                link_assume_external_targets: true,
              }}
              value={data.data}
              onEditorChange={(c) => setData({ ...data, data: c })}
            />
          </div>
          <div className="form-group inputDiv mt-3">
            <button
              disabled={btnDisabled}
              onClick={updateChildDetails}
              className="btn btn-sm btn-dark"
            >
              {btnUpdate}
            </button>
            <button
              disabled={btnDisabled}
              onClick={deleteChildDetails}
              className="btn btn-sm btn-dark"
            >
              {btnDelete}
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {selected.title}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setSelected({ id: "", title: "", size: "" })}
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selected.title}
                    onChange={(e) => setSelected({ ...selected, title: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label>Size</label>
                  <select
                    className="form-control"
                    value={selected.size}
                    onChange={(e) => setSelected({ ...selected, size: e.target.value })}
                  >
                    <option value="">Select Size</option>
                    <option value="12" selected={selected.size === "12"}>
                      1
                    </option>
                    <option value="6" selected={selected.size === "6"}>
                      2
                    </option>
                    <option value="4" selected={selected.size === "4"}>
                      3
                    </option>
                  </select>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  disabled={btnDisabled}
                  className="btn btn-dark btn-sm"
                >
                  {modalTitle}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditSubHeadingDetails;
