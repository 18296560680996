import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { useLocation, Link, useNavigate } from "react-router-dom";
import api from "../axios/api";
import "./Details.scss";
import DOMPurify from "dompurify";
import HomeIcon from "@mui/icons-material/Home";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import DownloadIcon from "@mui/icons-material/Download";

const Details = () => {
  const { state } = useLocation();
  const [data, setdata] = useState(null);
  const [pdf, setpdf] = useState(null);
  const [description, setDescription] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    (function () {
      const formData = new FormData();
      formData.append("hid", state.hid);
      formData.append("shid", state.shid);
      formData.append("date", state.date);
      api
        .post("Home/innerPage", formData)
        .then((res) => {
          if (res.data.type === "success") {
            setdata(res.data.data.heading);
            setpdf(res.data.data.pdf);
            setDescription(res.data.data.subtitle_description);
            setLoading(false);
          } else {
            setdata(null);
            setpdf(null);
            setLoading(false);
          }
        })
        .catch((err) => {
          alert(err);
        });
    })();
  }, []);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <Fragment>
      <div className="container">
        <div className="center">
          <h3>{`${state.title} - (${state.title
            .split(" ")
            .map((x) => x[0])
            .join("")}) `}</h3>
          <p>
            <p>{description && description.sub_title}</p>
          </p>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="p-2 monthHeading">
              <p>
                Details as on{" "}
                {data && moment(data[0].details_date).format("MMMM DD, YYYY")}
              </p>
              <div className="icons">
                <button className="btn text-white p-0" onClick={() => navigate(-1)}>
                  <HomeIcon className="icon" />
                </button>
                <button className="text-white btn p-0" to={""}>
                  <MarkunreadIcon className="icon" />
                </button>
                <a className="text-white" href={pdf ? pdf.url : "/"} target="_black">
                  <DownloadIcon className="icon" />
                </a>
              </div>
            </div>
          </div>
          {!loading ? (
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <div className="row">
                {data ? (
                  data.map((item, key) => {
                    return (
                      <div key={key} className={`col-md-${item.size} col-sm-12`}>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={`flush-headingOne-${key}`}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#flush-collapse-${key}`}
                              aria-expanded="false"
                              aria-controls={`flush-collapse-${key}`}
                            >
                              {item.title}
                            </button>
                          </h2>
                          <div
                            id={`flush-collapse-${key}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`flush-headingOne-${key}`}
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {item.value &&
                                item.value.map((val, key) => {
                                  return (
                                    <div
                                      key={key}
                                      className="body"
                                      dangerouslySetInnerHTML={createMarkup(val.data)}
                                    ></div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="loader">
                    <p>No Data Found</p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="loaderCenter">
              <svg
                className="spinner"
                width="65px"
                height="65px"
                viewBox="0 0 66 66"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="circle"
                  fill="none"
                  strokeWidth="6"
                  strokeLinecap="round"
                  cx="33"
                  cy="33"
                  r="30"
                ></circle>
              </svg>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Details;
