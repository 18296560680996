import React, { useState, useEffect, Fragment } from "react";
import "./AddHeading.scss";

import api from "../../axios/api";
import { useLogin } from "./../../context/Provider";

const AddHeading = () => {
  const { token, setToken, setIsLoggedin } = useLogin();
  // current date
  let year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;
  let monthEdited = "";
  if (month.toString().length === 1) {
    monthEdited = `0${month}`;
  } else {
    monthEdited = month;
  }

  // btn state
  const [btnTitle, setBtnTitle] = useState("Add Heading");
  const [btnDisabled, setBtnDisabled] = useState(false);

  // form state
  const [input, setInput] = useState({
    month: `${year}-${monthEdited}`,
    title: "",
    parent_id: "",
    subTitle: "",
    subDetails: "",
  });

  // title submit
  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnDisabled(true);
    setBtnTitle("Adding");
    const formData = new FormData();
    formData.append("date", input.month);
    formData.append("title", input.title);
    api
      .post("Home/addHeading", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setInput({ ...input, title: "", parent_id: "", subTitle: "", subDetails: "" });
          setBtnTitle("Added");
          getHeading();
          setTimeout(() => {
            setBtnTitle("Add Heading");
            setBtnDisabled(false);
          }, 2000);
        } else {
          setBtnTitle("Add Heading");
          setBtnDisabled(false);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        setBtnTitle("Try Again");
        setTimeout(() => {
          setBtnTitle("Add Heading");
          setBtnDisabled(false);
        }, 2000);
      });
  };

  const [heading, setHeading] = useState(null);
  const [subBtnDisabled, setSubBtnDisabled] = useState();
  const [subBtnTitle, setSubBtnTitle] = useState("Add Subheading");

  // sub title submit
  const handleSubheadingSubmit = (event) => {
    event.preventDefault();
    setSubBtnDisabled(true);
    setSubBtnTitle("Adding");
    const formData = new FormData();
    formData.append("date", input.month);
    formData.append("title", input.subTitle);
    formData.append("sub_title", input.subDetails);
    formData.append("parent_id", input.parent_id);
    api
      .post("Home/addSubheading", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          setInput({
            month: `${year}-${monthEdited}`,
            title: "",
            parent_id: "",
            subTitle: "",
            subDetails: "",
          });
          setSubBtnTitle("Added");
          setTimeout(() => {
            setSubBtnTitle("Add Subheading");
            setSubBtnDisabled(false);
          }, 2000);
        } else {
          setSubBtnTitle("Add Subheading");
          setSubBtnDisabled(false);
        }
      })
      .catch((err) => {
        if (err.response.data.type === "error") {
          setToken(null);
          setIsLoggedin(false);
        }
        setSubBtnTitle("Try Again");
        setTimeout(() => {
          setSubBtnTitle("Add Subheading");
          setSubBtnDisabled(false);
        }, 2000);
      });
  };

  // get heading
  const getHeading = () => {
    const formData = new FormData();
    formData.append("date", input.month);
    api
      .post("Home/getHeading", formData)
      .then((res) => {
        if (res.data.type === "success") {
          setHeading(res.data.data);
        } else if (res.data.type === "error") {
          setHeading(res.data.data);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    getHeading();
  }, [input.month]);

  return (
    <Fragment>
      <div className="main">
        <div className="row">
          <div className="col-6">
            <h3>Add Heading</h3>
            <div className="form">
              <form onSubmit={handleSubmit}>
                <div className="form-group inputDiv">
                  <label>Month</label>
                  <input
                    type="month"
                    value={input.month}
                    onChange={(e) => setInput({ ...input, month: e.target.value })}
                    className="form-control"
                  />
                </div>
                <div className="form-group inputDiv">
                  <label>Heading Title</label>
                  <input
                    type="text"
                    value={input.title}
                    onChange={(e) => setInput({ ...input, title: e.target.value })}
                    className="form-control"
                  />
                </div>
                <div className="form-group inputDiv text-end">
                  <button
                    type="submit"
                    disabled={btnDisabled}
                    className="btn btn-sm btn-dark"
                  >
                    {btnTitle}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-6">
            <h3>Add Subheading</h3>
            <div className="form">
              <form onSubmit={handleSubheadingSubmit}>
                <div className="form-group inputDiv">
                  <label>Heading</label>
                  <select
                    className="form-control"
                    value={input.parent_id}
                    onChange={(e) => setInput({ ...input, parent_id: e.target.value })}
                  >
                    <option value="">Select Heading</option>
                    {heading &&
                      heading.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {item.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="form-group inputDiv">
                  <label>Subheading Title</label>
                  <input
                    type="text"
                    value={input.subTitle}
                    onChange={(e) => setInput({ ...input, subTitle: e.target.value })}
                    className="form-control"
                  />
                </div>
                <div className="form-group inputDiv">
                  <label>Subheading Description</label>
                  <textarea
                    onChange={(e) => setInput({ ...input, subDetails: e.target.value })}
                    className="form-control"
                    cols="30"
                    rows="5"
                    value={input.subDetails}
                  />
                </div>
                <div className="form-group inputDiv text-end">
                  <button
                    type="submit"
                    disabled={subBtnDisabled}
                    className="btn btn-sm btn-dark"
                  >
                    {subBtnTitle}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddHeading;
