import React, { Fragment } from "react";
import "./Sidebar.scss";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PasswordIcon from "@mui/icons-material/Password";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Link, NavLink } from "react-router-dom";

const Sidebar = () => {
  const logout = () => {
    sessionStorage.removeItem("encryptedData");
    window.location.href = "/admin";
  };
  return (
    <Fragment>
      <div className="sidebar">
        <div className="top">
          <span className="logo">Admin</span>
        </div>
        <hr />
        <div className="center">
          <ul>
            <p className="title">Main</p>
            <NavLink className={({ isActive }) => (isActive ? "active" : "")} to="/admin">
              <li>
                <DashboardRoundedIcon className="icon" />
                <span>Dashboard</span>
              </li>
            </NavLink>
            <p className="title">Heading</p>
            <NavLink
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#e2e2e2" : "",
                };
              }}
              to="addHeading"
            >
              <li>
                <AddCircleOutlineRoundedIcon className="icon" />
                <span>Add Heading</span>
              </li>
            </NavLink>
            <NavLink
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#e2e2e2" : "",
                };
              }}
              to="addHeadingDetails"
            >
              <li>
                <AddBoxRoundedIcon className="icon" />
                <span>Add SubHeading Details</span>
              </li>
            </NavLink>
            <p className="title">Edit Heading</p>
            <NavLink
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#e2e2e2" : "",
                };
              }}
              to="EditHeading"
            >
              <li>
                <EditIcon className="icon" />
                <span>Edit Heading</span>
              </li>
            </NavLink>
            <NavLink
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#e2e2e2" : "",
                };
              }}
              to="editSubHeading"
            >
              <li>
                <EditIcon className="icon" />
                <span>Edit SubHeading Details</span>
              </li>
            </NavLink>
            <p className="title">General Settings</p>
            <NavLink
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#e2e2e2" : "",
                };
              }}
              to="addNavbarImage"
            >
              <li>
                <ImageIcon className="icon" />
                <span>Add Navar Image</span>
              </li>
            </NavLink>
            <NavLink
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#e2e2e2" : "",
                };
              }}
              to="addHomeImage"
            >
              <li>
                <ImageIcon className="icon" />
                <span>Add Home Image</span>
              </li>
            </NavLink>
            <NavLink
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#e2e2e2" : "",
                };
              }}
              to="addPdf"
            >
              <li>
                <PictureAsPdfIcon className="icon" />
                <span>Add Pdf</span>
              </li>
            </NavLink>
            <NavLink
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#e2e2e2" : "",
                };
              }}
              to="detailsDate"
            >
              <li>
                <EditIcon className="icon" />
                <span>Edit Details Date</span>
              </li>
            </NavLink>
            <NavLink
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#e2e2e2" : "",
                };
              }}
              to="homepageDate"
            >
              <li>
                <DateRangeIcon className="icon" />
                <span>Change Homepage Date</span>
              </li>
            </NavLink>
            <p className="title">Template</p>
            <NavLink
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#e2e2e2" : "",
                };
              }}
              to="copy"
            >
              <li>
                <ContentCopyIcon className="icon" />
                <span>Copy Template</span>
              </li>
            </NavLink>
            <p className="title">Account</p>
            <NavLink
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#e2e2e2" : "",
                };
              }}
              to="changePassword"
            >
              <li>
                <PasswordIcon className="icon" />
                <span>Change Password</span>
              </li>
            </NavLink>
          </ul>
        </div>
        <div className="bottom">
          <ul>
            <Link to="">
              <li onClick={logout}>
                <AddBoxRoundedIcon className="icon" />
                <span>Logout</span>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
